import 'babel-polyfill'
import 'react-app-polyfill/ie11'
import React, { Component } from 'react'
import Layout from '../../components/layout';
import { getObject } from '../../services/storage'
import { Container, Row, Col } from 'reactstrap'
import { navigate } from 'gatsby';
import { isLoggedIn, isBrowser } from '../../services/auth'
import { ResBar } from '../../components/sidebar';
import { Banner } from '../../components/banner'
import { handleCmsFile } from '../../services/api'

export default class resources extends Component {
    constructor(props) {
        super(props);

        // let objContent = "resource.json"
        // let envPrefix = "staging"
        // const objUri = envPrefix + '-' + objContent;

        this.state = {
            objItems: false,
            objStyle: false
        }; 
       
        // if(isBrowser()) this.renderObject(objUri)
    }

    renderObject = async (objVar) => {

        this.setState({
            objItems : await getObject(objVar),
        })

    }

    renderStyle = async (objVar) => {
        this.setState({
            objStyle : await getObject(objVar),
        })
    }

    async componentDidMount() {
        if(!isLoggedIn()){
            navigate('/signin/?referrer=' + encodeURIComponent(this.props.location.pathname))
        }

        let result
        var data = {
            stage_file: 'staging-resource.json',
            status: "current"
        }

        
        let files = await handleCmsFile(data)
        // console.log(files)
        if(files.data){
            files.data.forEach(function(item,key){
                if(item.status === 'current'){
                    result = item.data      
                }
            })
        }
        

        console.log(result)
        this.setState({
            objItems: result
        })
    }

    render() {
        // if(this.state.objItems.post_content !== undefined){
        //     var content = this.state.objItems.post_content
        // }else{
            var content = this.state.objItems
        // }
        if(isLoggedIn()){
            return (
                <Layout>
                    <Banner title={`Resources`} />
                    <Container fluid>
                        <Row>
                            <Col md={2} lg={2} className="d-none d-lg-block">
                                <ResBar/>
                            </Col>
                            <Col lg={10} md={10} sm={12}>
                                <div className="blog-post-container">
                                    <div className="blog-post">
                                        <div
                                        className="blog-post-content"
                                        dangerouslySetInnerHTML={{ __html: content }}
                                        />
                                    </div>
                                </div>

                            </Col>
                        </Row>
    
                    </Container>
                </Layout>
            )
        } else {
            return ''
        }
        
    }
}
